<template>
  <div>
    <div class="gip-bg-default">
      <div class="gip-page">
        <div class="back-title">
          <h1>Mes demandes</h1>
        </div>
        <BackHeader title="Mes Demandes">
          <b-form-select
            id="typeSpectacleChooser"
            @change="updateSpectacles()"
            v-model="typeSpectacle"
            :options="typesSpectacles"
          ></b-form-select>
          <b-form-select
            id="anneeChooser"
            @change="updateSpectacles()"
            v-model="annee"
            :options="annees"
          ></b-form-select>
        </BackHeader>
        <b-table
            :show-detail-icon="false"
            detailed
            :detail-transition="null"
            :data="spectacles"
            class="table-demandes">
          <b-table-column field="dateRepresentation" label="" width="10" >
            <template v-slot:header="{  }">
            </template>
            <template v-slot="props">
              <b-icon v-if="new Date(props.row.dateRepresentation) >= new Date('2023-07-01') && props.row.photoCount <= 0 && !isAfterToday(props.row.dateRepresentation)"
                      icon="alert"
                      type="is-warning">
              </b-icon>
            </template>
          </b-table-column>

          <b-table-column field="dateRepresentation" label="Date du spectacle"  >
            <template v-slot:header="{  }">
                Date du spectacle
            </template>
            <template v-slot="props">
              {{ display("Spectacle.dateRepresentation", props.row.dateRepresentation) }}
            </template>
          </b-table-column>

          <b-table-column field="nom" label="Titre du spectacle">
            <template v-slot:header="{  }">
              Titre du spectacle
            </template>
            <template v-slot="props">
              {{ props.row.nom }}
            </template>
          </b-table-column>

          <b-table-column field="montant" label="Montant de l'aide">
            <template v-slot:header="{  }">
              Montant de l'aide
            </template>
            <template v-slot="props">
              {{ props.row.montant }} €
            </template>
          </b-table-column>

          <b-table-column field="montant" label="État">
            <template v-slot:header="{  }">
              État
            </template>
            <template v-slot="props">
              <p class="" :style="statusColor(props.row.etat)">
                {{ display("Spectacle.etat", props.row.etat) }}
              </p>
            </template>
          </b-table-column>

          <b-table-column field="" label="">
            <template v-slot:header="{  }">

            </template>
            <template v-slot="props">
              <hbox style="justify-content: space-evenly;">
              <icon-base
                  @click.native="props.toggleDetails(props.row)"
                  :class="{
                      hidden: ['N', 'V'].includes(props.row.etat),
                    }"
                  title="Afficher/Masquer les détails de cette demande"
              >
                <eye-small
                    :ref="'eye-' + props.index"
                    :class="{
                        hidden: isCellExpanded(props.index),
                      }"
                ></eye-small>
                <eye-stroke-small
                    :ref="'eye-stroke-' + props.index"
                    :class="{
                        hidden: !isCellExpanded(props.index),
                      }"
                ></eye-stroke-small>
              </icon-base>
              <icon-base
                  @click.native="downloadDocument(props.row)"
                  :title="tooltipDoc(props.row)"
                  class="download-icon"
              >
                <download-small></download-small>
              </icon-base>
              <icon-base
                  active
                  iconColor="var(--gip-white-color)"
                  background-color="var(--gip-error-color)"
                  :class="{
                      hidden: !['R', 'X'].includes(props.row.etat),
                    }"
                  @click.native="deleteDocument(props.row)"
                  title="Supprimer cette demande"
              >
                <trash-small></trash-small>
              </icon-base>
              </hbox>
            </template>
          </b-table-column>
          <template  #detail="props">
            <h5 class="photo-titre">Informations sur l'événement</h5>
            <hr>
            <hbox class="demande-expanded flex-wrap">
              <vbox class="fill demande-lieu">
                <p>
                  Lieu :
                  {{ display("Spectacle.categorie", props.row.categorie) }}
                </p>
                <p>
                  Nombre d’artistes et/ou techniciens concernés :
                  {{ props.row.intervenants.length }}
                </p>
              </vbox>
              <vbox class="fill">
                <p>
                  Date de la demande :
                  {{ formatDate(props.row.dateDemande) }}
                </p>
                <p>
                  Date de la représentation :
                  {{ formatDate(props.row.dateRepresentation) }}
                </p>
                <p v-if="props.row.raisonRefus">
                    Raison du refus :
                    {{ props.row.raisonRefus }}
                  </p>
              </vbox>
            </hbox>
         <PhotosSpectacles :spectacleId="props.row.spectacleId"
                          :getImage="(spectacleId,index) => getImage(props.row, index)"
                          :addPicToDoc="doc => addPicToDoc(doc,props.row.spectacleId)"
                           :autoSend="true"
                          :removePicFromDoc="(doc) => removePicFromDoc(doc,props.row.spectacleId)"
                          :photoCount="props.row.photoCount"
                          :anticipe="isAfterToday(props.row.dateRepresentation)"/>

          </template>
<!--          <b-table-column field="date" label="Date" centered v-slot="props">-->
<!--            <span class="tag is-success">-->
<!--                {{ new Date(props.row.date).toLocaleDateString() }}-->
<!--            </span>-->
<!--          </b-table-column>-->


        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import CompositeTable from "./CompositeTable";
import BackHeader from "../ui/back/BackHeader";
import kit from "../../kit/kit";
import GipMixin from "../ui/GipMixin";
import CObject from "../../kit/data/CObject";
import PhotosSpectacles from "@/components/ui/PhotosSpectacles.vue";
import DateExt from '@/kit/misc/DateExt'

export default {
  name: "Demandes",
  components: { PhotosSpectacles, BackHeader },
  mixins: [GipMixin],
  data() {
    return {
      photoCount:0,
      // http://localhost:8081/services/query?cclass=Employeur&id=13&depth=3
      employeur: {
        __cclass: "Employeur",
        __id: "13",
        employeurId: "13",
        nom: "BUCK MULLIGAN'S",
        formeJuridiqueId: {
          __cclass: "FormeJuridique",
          __id: "1",
          formeJuridiqueId: "1",
          numero: "54",
          nom: "SARL",
        },
        raisonSociale: "SARL CROISIERE CELTIQUE",
        nomGerant: "Barraud",
        prenomGerant: "Anthony",
        nomContact: "Brétéché",
        prenomContact: "Julien",
        tel: "02 40 20 02 72",
        tel2: "06 22 16 06 44",
        mail: "a.barraud@monade.co",
        guso: "0278585111",
        password:
          "$2b$10$C60tKaD1fpKeu9KhrkVIeeJDz5TwkCjdnVdc0YPeNshrr/5duvW8C",
        salt: "$2a$10$qpe50ntEwBbkPf3G5S2An.",
        siret: "43996834800045",
        naf: {
          __cclass: "NAF",
          __id: "5630Z",
          nafId: "5630Z",
          nom: "Débits de boissons",
          eligibilite: "NORMALE",
        },
        etablissementBancaire: "Banque Populaire",
        iban: "FR76 1380 7003 6930 3217 1375 516",
        bic: "CCBPFRPPNAN",
        licence: "O",
        numeroLicence: "1-1121885 2-1121886 3-1121887",
        etablissementEnCentreCommercial: "N",
        categorieErp: "5",
        typeErp: "N",
        licenceRestauration: "N",
        valide: "O",
        adresse1: "12 RUE DU CHÂTEAU",
        communeId: {
          __cclass: "Commune",
          __id: "17099",
          communeId: "17099",
          insee: "44109",
          codePostal: "44000",
          nom: "NANTES",
          latitude: "47.239700",
          longitude: "-1.549200",
          population: "306694",
        },
        objectId: "13",
        tags: [],
        notifMail: "O",
        avisCommissionSecurite: "O",
        autoriserDemandeurTiers: "O",
        dateCreation: "2016-01-29",
        lastUpdate: "2021-09-23",
        archive: "N",
        documents: [],
        spectacles: [],

      },
      spectacles: [],
      fixedSpec : [],
      typeSpectacle: "T",
      typesSpectacles: [
        { value: "T", text: "Toutes" },
        { value: "X", text: "En attente" },
        { value: "R", text: "Refusées" },
        { value: "P", text: "Payées" },
      ],

      annee: new Date().getFullYear(),
      annees: [
        { value: "Toutes", text: "Toutes" },
        { value: "2015", text: "2015" },
        { value: "2016", text: "2016" },
        { value: "2017", text: "2017" },
        { value: "2018", text: "2018" },
        { value: "2019", text: "2019" },
        { value: "2020", text: "2020" },
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
      ],
      // a supprimer
      // dateSpectacle: "10/09/2021",
      // dateDemande: "10/08/2019",
      // titre: "La mélodie du bonheur",
      // montant: "258,45",
      // lieu: "Salon de provence",
      // nbTechnicien: "3"
    };
  },
  created() {
    this.newFeature = this.state.newFeature
    const current = new Date().getFullYear()
    let ans = [ { value: "Toutes", text: "Toutes" }]
    for (let i = 2015; i < current + 1; i ++) {
      ans.push({ value: String(i), text: String(i) })
    }
    this.annees = ans
    if(this.state.user){
      this.employeur = this.state.user;
      this.spectacles = this.state.user.spectacles
      this.updateSpectacles()
    }

  },
  watch:{
    'state.user'(newv,oldv){
      this.spectacles = newv.spectacles;
      this.employeur = newv
      this.updateSpectacles()
    }
  },

  methods: {

    getImage(spectacle, index) {
      for (var i = 0; i < spectacle.photos.length; i++) {
        if (spectacle.photos[i].ordre == index)
          return spectacle.photos[i]
      }
      return null
    },


    /**
     * Met à jour les spectacles en fonction de l'année et de l'état sélectionné
     */
    updateSpectacles() {
      // pour le filtrage sur les etats on fait
      // en attente (X) couvre les en attente (X) et pré-financé (E)
      // refusé (R) couvre refusé (R) et suspendu (S)
      // T : tout le monde
      // P : payé (l'état accepté ne dure que le temps de régler)
      this.document = this.employeur.documents
      this.spectacles = this.employeur?.spectacles.filter((d) => {
        var date = kit.model
          .field("Spectacle.dateRepresentation")
          .convert(d.dateRepresentation);
        d.photoCount = d.photos.length;

              //  console.log("annee=" + date.getFullYear() + " etat=" + d.etat);
        return (
          (this.annee === "Toutes" ||
            date.getFullYear() === parseInt(this.annee)) &&
          (this.typeSpectacle === "T"
            ? true
            : this.typeSpectacle === "X" && (d.etat === "X" || d.etat === "E")
            ? true
            : this.typeSpectacle === "R" && (d.etat === "R" || d.etat === "S")
            ? true
            : this.typeSpectacle === "P" && d.etat === "P"
            ? true
            : false)
        );
      });
      console.log(this.spectacles)

    },
    statusColor(etat) {
     // console.log("etat=" + etat);
      var enumEtat = kit.model.cclass("Spectacle").field("etat").value(etat);
     // console.log("bim statut=" + etat + " color=" + enumEtat.color);
      return (
        "color:rgb(" + enumEtat.color + ");" + "width:15em;flex-basis:auto;"
      );
    },
    isCellExpanded(index) {
      // check chelou, à l'init on peut avoir de la merde
      return this.$refs.demandeCell && this.$refs.demandeCell[index]
        ? this.$refs.demandeCell[index].isExpanded()
        : false;
    },
    func() {
      this.$refs.demandeCell.expand();
    },
    /** chargement du doc : avis de paiement si c'est payé ou alors fiche spectacle */
    downloadDocument(spectacle) {
      var service =
        spectacle.etat === "P" ? "avis-paiement" : "fiche-spectacle";
      window.open(
        kit.servicesUrl +
          "/" +
          service +
          "?spectacleId=" +
          spectacle.spectacleId,
        "_blank"
      );
    },
    addPicToDoc(img,id){
  console.log("what happrn ",img)
  console.log("what happrn ",id)
      let v = this.state.user.spectacles.find(elm => elm.spectacleId == id)
      v.photos.push(img)
      this.updateSpectacles()

    },
    removePicFromDoc(img,id){
      //this.spectacles.find((e) => id === e.spectacleId).PhotoCount -= 1
      let photos =  this.state.user.spectacles.find(elm => elm.spectacleId == id).photos
      let index = photos.indexOf(img)
      this.state.user.spectacles.find(elm => elm.spectacleId == id).photos.splice(index,1)
      this.updateSpectacles()

    },
    deleteDocument(spectacle) {

      kit.dialogs.confirm({
        action: "Supprimer",
        message: "Voulez-vous supprimer cette demande ?",
        callback: (e) => {
          var rdoc = new CObject("Spectacle", spectacle.spectacleId);
          kit.services.delete(
            rdoc,
            () => {
              //alert("suppression ok")
              // on supprime des spectacles ici (filtrés) et de tous (dans le contexte)
              var index = this.spectacles.indexOf(spectacle);
              this.spectacles.splice(index, 1);
              index = this.employeur.spectacles.indexOf(spectacle);
              this.employeur.spectacles.splice(index, 1);
            },
            (error) => {
              kit.dialogs.error("erreur de suppression");
              kit.log.error("erreur suppression : " + error);
            }
          );
        },
      });
    },
    tooltipDoc(spectacle) {
      return spectacle.etat === "P"
        ? "Télécharger l'avis de paiement"
        : "Télécharger la déclaration de spectacle";
    },
    isAfterToday(date) {
      var today = new Date().format('isoDate');
      return date > today
    }
  },
}
</script>

<style lang="scss" scoped>
.back-header {
  margin-bottom: 0.5em;
}
p {
  margin: 0;
}
#anneeChooser {
  flex-basis: fit-content;
  width: auto;
}

.demande-lieu {
  margin-right: 1em;
}

//h1 {
//  padding-top: 2em;
//  margin-bottom: 2em;
//}

.sphere-container {
}

.table-demandes {
  padding-bottom: 3.125em;
}
#typeSpectacleChooser {
  flex-basis: fit-content;
  width: auto;
  margin-right: 1em;
}

.demande-expanded {
  margin-bottom: 50px;
}

.detail-container {
  .photo-titre {
  font-size: 14px;
    span{
      color: red;
    }
  }
  hr {
    margin-top: 0;
  }
}

.phototext {
  padding: 8px;
  margin-bottom: 15px ;
  font-weight: 600;
  background: #f9fafb;
  &.info {
      padding: 16px;
    min-width: 500px;
  }
  &.alertp{
    padding-left: 16px;

  }
  .icon{
    margin-right: 15px;
  }
}



</style>

<style lang="scss">
.table {
  thead {
    background: #d5dce1;

    th span {
      color: var(--gip-tint-color);
      font-weight: 500;
      font-size: 15px;
    }


  }
  td {
    vertical-align: middle;
    padding: 10px;
  }
  .mdi:before{
    vertical-align: middle;
  }
}

.download-icon {
  margin: 0 5px;
}
</style>